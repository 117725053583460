<template>
  <el-submenu v-if="menu.children && menu.children.length >= 1" :index="menu.id + ''" :popper-class="'site-sidebar--' + sidebarLayoutSkin + '-popper'">
    <template slot="title">
      <i :class="menu.icon || ''"></i>
      <span>{{ menu.name }}</span>
    </template>
    <sub-menu v-for="item in menu.children" :key="item.menuId" :menu="item" :dynamicMenuRoutes="dynamicMenuRoutes">
    </sub-menu>
  </el-submenu>
  <el-menu-item v-else :index="menu.id + ''" @click="gotoRouteHandle(menu)">
    <i :class="menu.icon || ''"></i>
    <span>{{ menu.name }}</span>
  </el-menu-item>
</template>

<script>
import SubMenu from "./main-sidebar-sub-menu";
export default {
  name: "sub-menu",
  props: {
    menu: {
      type: Object,
      required: true,
    },
    dynamicMenuRoutes: {
      type: Array,
      required: true,
    },
  },
  components: {
    SubMenu,
  },
  computed: {
    sidebarLayoutSkin: {
      get() {
        return this.$store.state.common.sidebarLayoutSkin;
      },
    },
  },
  methods: {
    gotoRouteHandle(menu) {
      this.$router.push({ path: menu.url });
    },
  },
};
</script>
